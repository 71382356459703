
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-attendance-report',
  components: {
    Datatable,
  },
  data() {
    return {
      api_url: '',
      moment: '' as any,
      registration_number: '',
      detailsData: {},
      detailsDataAttendance: [],
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableData: [],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: 'Attendance Date',
          key: 'attendance_date',
          sortable: true,
          width: '5px',
        },

        {
          name: 'Present',
          key: 'present',
          sortable: true,
        },
        {
          name: 'Late',
          key: 'late',
          sortable: true,
        },
        {
          name: 'In Time',
          key: 'inTime',
          sortable: true,
        },
        {
          name: 'Out Time',
          key: 'out_time',
          sortable: true,
        },
        {
          name: 'Term No',
          key: 'term_no',
          sortable: true,
        },
        {
          name: 'Remarks',
          key: 'remarks',
          sortable: true,
        },
      ],
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.moment = moment;
  },
  methods: {
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      
      });
       let formData = new FormData();

      formData.set('registration_number', this.registration_number);
      let data = `${this.VUE_APP_API_URL}/api/trainee/attendance_report_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('registration_number', this.registration_number);
      await ApiService.post('trainee/attendance_report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('registration_number', this.registration_number);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/attendancereportExport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Trainee Attendance.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getAttendanceDetails() {
      this.load = true;
      ApiService.get(
        'trainee/attendance-report?registration_number=' +
          this.registration_number
      )
        .then((response) => {
          if (response.data.status == 'error') {
            this.showCourseNotice = false;
            this.load = false;
            Swal.fire({
              title: response.data.message,
              icon: 'error',
            });
          } else if (
            response.data.data &&
            response.data.data.trainee_attendance_info &&
            response.data.data.trainee_attendance_info.length === 0
          ) {
            this.showCourseNotice = false;
            this.load = false;
            Swal.fire({
              title: 'Attendance Record Not Found',
              icon: 'warning',
            });
          } else {
            this.detailsData = response.data.data.trainee_info;
            this.detailsDataAttendance =
              response.data.data.trainee_attendance_info;
            this.showCourseNotice = true;
            this.load = false;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {},
});
